// @import 'themes/functions';

.table-head {
  :not(:first-child) {
    padding-left: 0;
  }

  .table-head-row {
    .table-head-cell {
      background-color: #fed530;
      border-bottom: none;
      color: #12251a;
      font-family: 'SF UI Text', sans-serif;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      padding-right: 16px;
    }

    &:first-child > * {
      padding-bottom: 0;
    }

    &:last-child > * {
      padding-top: 0;
    }
  }
}
