// @import 'themes/functions';

// Table Body Style
.table-body {
  background-color: #fff;

  .table-body-row-empty {
    @extend .table-body-row;

    background-color: #f4f6f8;
  }

  .table-body-row {
    &-hover {
      &:hover {
        background-color: #00000012;
      }
    }

    &-stripped:nth-of-type(odd) {
      background-color: #6f6a6a0f;
    }

    :not(:first-child) {
      padding-left: 0;
    }

    .table-body-cell {
      border-bottom: 1px solid #c8c8c8;
      color: #3c3b3b;
      font-family: 'Lato-Bold', 'Helvetica-Bold', 'Arial', sans-serif;
      font-size: 9px;
      height: 32px;
      overflow: hidden;
      padding-bottom: 8px;
      padding-top: 12px;
      text-overflow: ellipsis;

      &-clickable {
        cursor: pointer;
      }

      &-padding {
        border-bottom: 1px solid #f4f6f8;
      }
    }

    .cell-no-data {
      @extend .table-body-cell;

      background-color: #fff;
      text-align: center;
    }

    &-rest {
      @extend .table-body-row;

      background-color: #f4f6f8;
    }
  }
}
