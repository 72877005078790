$search-input: 8px;
$last-update: 10px;

.dashboard-container {
  background-color: #fafafa;

  .search-field {
    width: 178.67px;

    &.margin {
      margin-bottom: 17px;
    }
  }

  .row-1 {
    margin-bottom: 17px;
    margin-top: 10.67px;

    .search-wrapper {
      display: flex;

      .search-field:first-child {
        margin-right: 16.67px;
      }
    }

    .status-wrapper {
      .last-update__label {
        color: #959595;
        font-family: 'SF UI Text', sans-serif;
        font-size: $search-input;
      }

      .last-update__date {
        color: #323232;
        font-family: 'SF UI Text', sans-serif;
        font-size: $last-update;
        font-weight: bold;
      }
    }
  }

  .row-2 {
    margin-bottom: 20.87px;

    .header-card {
      border-radius: 6.66px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      // height: 73.33px;
      position: relative;

      &__content {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        padding: 7.73px 10.67px;
      }

      &__content:last-child {
        padding-bottom: 8.67px;
      }

      &__title {
        color: #959595;
        font-family: 'SF UI Text', sans-serif;
        font-size: 10px;
        font-weight: bold;
        left: 10.67px;
        line-height: 12px;
        position: absolute;
        top: 6px;
      }

      &__percentage {
        align-items: baseline;
        color: #000;
        display: flex;
        font-family: 'SF UI Text', sans-serif;
        font-size: 33.33px;
        font-weight: bold;
        justify-content: center;
        line-height: 40px;
        padding-top: 6.67px;
      }

      .percentage-symbol {
        color: #000;
        font-family: 'SF UI Text', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: bold;
        line-height: 24px;
      }

      &__value {
        color: #000;
        font-family: 'SF UI Text', sans-serif;
        font-size: 18.66px;
        font-weight: bold;
        line-height: 22px;
      }

      &__label {
        color: #959595;
        font-family: 'SF UI Text', sans-serif;
        font-size: 6.66px;
        font-weight: 500;
        line-height: 8px;
      }
    }
  }

  .divider {
    margin-bottom: 30.13px;
  }

  .upper-value {
    color: #000;
    font-family: 'SF UI Text', sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;

    &.status {
      align-items: center;
      display: flex;
    }

    .bullet {
      border-radius: 8px;
      height: 8px;
      margin-right: 2px;
      width: 8px;

      &.abnormal {
        background-color: #f36e6e;
      }

      &.good {
        background-color: #43cc82;
      }
    }
  }

  .lower-value {
    color: #9a9a9a;
    font-family: 'SF UI Text', sans-serif;
    font-size: 10px;
    line-height: 18px;

    &.abnormal,
    &.minus {
      color: #f36e6e;
    }

    &.good,
    &.plus {
      color: #43cc82;
    }

    &.plus::before {
      content: '+';
    }
  }

  .chart-container {
    background-color: #fff;
    margin-top: 22px;
    padding-bottom: 12px;
    padding-top: 12px;

    .chart-title {
      color: #251212;
      font-family: 'SF UI Text', sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
    }

    .chart-total {
      color: #251212;
      font-family: 'SF UI Text', sans-serif;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }

    .chart-status {
      &-label {
        color: #000;
        font-family: 'SF UI Text', sans-serif;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
      }

      &-value {
        color: #000;
        font-family: 'SF UI Text', sans-serif;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }

  .table-chart__container {
    margin-bottom: 20.87px;
  }

  .title {
    color: #000;
    font-family: 'SF UI Text', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;

    &-container {
      margin-bottom: 23px;
    }

    &-section {
      border-left: 5px solid #fed530;
      color: #000;
      font-family: 'SF UI Text', sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.32px;
      margin-bottom: 16px;
      padding-left: 10px;
    }
  }

  .sub-title {
    color: #9a9a9a;
    font-family: 'SF UI Text', sans-serif;
    font-size: 11.33px;
    line-height: 13.52px;

    &-group {
      align-items: center;
      display: flex;
    }

    &-bullet {
      border-radius: 8px;
      height: 8px;
      margin-right: 2px;
      width: 8px;

      &.abnormal {
        background-color: #f36e6e;
      }

      &.good {
        background-color: #43cc82;
      }
    }
  }

  .health-score__container {
    margin-bottom: 18px;

    .button-label {
      font-size: 7px;
      line-height: 9px;
    }

    .button-filter {
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 0.666667px 2.66667px -0.666667px rgba(0, 0, 0, 0.15);
      font-family: 'SF UI Text', sans-serif;
      font-weight: 500;
      height: 17.33px;
      margin-right: 10.67px;
      min-height: 17.33px;
      min-width: 35.33px;

      &.active {
        background-color: #fed530;
      }

      &__container {
        margin-bottom: 24px;
      }
    }
  }

  .overview__container {
    margin-bottom: 24px;

    .table-overview {
      border-collapse: collapse;
      width: 100%;

      tr > * {
        border-left: 2px solid #d6d5d5;
        padding-left: 10px;
      }

      .head-overview {
        color: #251212;
        font-family: 'SF UI Text', sans-serif;
        font-size: 10px;
        line-height: 12px;
      }

      .value-overview {
        padding-bottom: 18.4px;
      }

      .value-overview__container {
        color: #251212;
        font-family: 'SF UI Text', sans-serif;
        font-size: 10px;
        font-weight: bold;
        line-height: 12px;
      }
    }
  }
}
