$search-height: 27px;
$search-input: 8.67px;
$box-shadow-card: 1px 2px 4px #0000000d;

.search {
  &-field {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: $box-shadow-card;
    display: flex;
    height: $search-height;
    justify-content: flex-start;
    padding-left: 13.33px;
  }

  &-icon {
    font-size: 18px;
    margin-right: 5px;
  }

  &-input {
    font-family: 'SF UI Text', sans-serif;
    font-size: $search-input;
  }
}
