.font-style {
  color: #323232;
  font-family: 'SF UI Text', sans-serif;
  font-size: 8px;
  font-weight: bold;
  line-height: 10px;
}

.link {
  @extend .font-style;

  display: block;
}

.location {
  @extend .font-style;

  color: #4da1ff;
}

.separator {
  font-size: 10px;
}

.breadcrumbs-container {
  margin-top: 10px;
}
